@font-face {
  font-family: 'Manrope-Regular';
  src: local('Manrope'), url(./fonts/Manrope-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Manrope-Medium';
  src: local('Manrope'), url(./fonts/Manrope-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Manrope-SemiBold';
  src: local('Manrope'), url(./fonts/Manrope-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Manrope-Bold';
  src: local('Manrope'), url(./fonts/Manrope-Bold.ttf) format('truetype');
}

body {
  margin: 0;
}